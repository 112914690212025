import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from './LoginPage';
import Admin from './Admin';
import Yesterday from './Yesterday';
import YesterdaySingle from './Yesterday_single';
import YesterdayVip from './Yesterday_vip';
import YesterdayTotal15 from './Yesterday_total_1_5';
import YesterdayVipHomeWin from './Yesterday_vip_home_win';
import YesterdayAllGold from './Yesterday_all_gold_cards';
import YesterdayEvents from './YesterdayEvents';
import Today from './Today';
import TodaySingle from './Today_single';
import TodayVip from './Today_vip';
import TodayTotal15 from './Today_total_1_5';
import TodayVipHomeWin from './Today_vip_home_win';
import TodayAllGold from './Today_all_gold_cards';
import TodayEvents from './TodayEvents';
import Tomorrow from './Tomorrow';
import TomorrowSingle from './Tomorrow_single';
import TomorrowVip from './Tomorrow_vip';
import TomorrowTotal15 from './Tomorrow_total_1_5';
import TomorrowVipHomeWin from './Tomorrow_vip_home_win';
import TomorrowAllGold from './Tomorrow_all_gold_cards';
import TomorrowEvents from './TomorrowEvents';
import AfterTomorrowEvents from './AfterTomorrowEvents';
import AfterTomorrow from './AfterTomorrow';
import AfterTomorrowSingle from './AfterTomorrow_single';
import AfterTomorrowVip from './AfterTomorrow_vip';
import AfterTomorrowTotal15 from './AfterTomorrow_total_1_5';
import AfterTomorrowVipHomeWin from './AfterTomorrow_vip_home_win';
import AfterTomorrowAllGold from './AfterTomorrow_all_gold_cards';

import DayAfterTomorrowEvents from './DayAfterTomorrowEvents';
import DayAfterTomorrow from './DayAfterTomorrow';
import DayAfterTomorrowVip from './DayAfterTomorrow_vip';
import DayAfterTomorrowTotal15 from './DayAfterTomorrow_total_1_5';
import DayAfterTomorrowAllGold from './DayAfterTomorrow_all_gold_cards';
import DayAfterTomorrowSingle from './DayAfterTomorrow_single';

import Live from './Live';
import JackPot from './JackPot';
import JackPotLast1 from './JackPot_last_1';
import JackPotLast2 from './JackPot_last_2';
import JackPotLast3 from './JackPot_last_3';
import Statistics15 from './Statistics_15';
import Statistics30 from './Statistics_30';
import Statistics60 from './Statistics_60';
import Statistics90 from './Statistics_90';
import Statistics180 from './Statistics_180';
import PayAmount100 from './Pay_amount_100';

function App() {

  const isAuthenticated = localStorage.getItem('authenticated') === 'true';

  return (
    <Router>
      <div>
      <Helmet>
      <title>Total93</title>
                <meta name="description" content="Total93" />
                <script 
                    id="Cookiebot" 
                    src="https://consent.cookiebot.com/uc.js" 
                    data-cbid="9c7aeaa4-2a9e-442f-890f-7289a69cc7ee" 
                    data-blockingmode="auto" 
                    type="text/javascript"
                ></script>
            </Helmet>
        <Switch>
        <Route path="/admin">
            {isAuthenticated ? (
              <Admin />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/yesterday">
            <Yesterday />
          </Route>
          <Route path="/yesterday_single">
            <YesterdaySingle />
          </Route>
          <Route path="/yesterday_vip">
            <YesterdayVip />
          </Route>
          <Route path="/yesterday_total_1_5">
            <YesterdayTotal15 />
          </Route>
          <Route path="/yesterday_vip_home_win">
            <YesterdayVipHomeWin />
          </Route>
          <Route path="/yesterday_all_gold_cards">
            <YesterdayAllGold />
          </Route>
          <Route path="/yesterday_events">
            <YesterdayEvents />
          </Route>
          <Route path="/today">
            <Today />
          </Route>
          <Route path="/today_single">
            <TodaySingle />
          </Route>
          <Route path="/today_vip">
            <TodayVip />
          </Route>
          <Route path="/today_total_1_5">
            <TodayTotal15 />
          </Route>
          <Route path="/today_vip_home_win">
            <TodayVipHomeWin />
          </Route>
          <Route path="/today_all_gold_cards">
            <TodayAllGold />
          </Route>
          <Route path="/today_events">
            <TodayEvents />
          </Route>
          <Route path="/tomorrow">
            <Tomorrow />
          </Route>
          <Route path="/tomorrow_single">
            <TomorrowSingle />
          </Route>
          <Route path="/tomorrow_vip">
            <TomorrowVip />
          </Route>
          <Route path="/tomorrow_total_1_5">
            <TomorrowTotal15 />
          </Route>
          <Route path="/tomorrow_vip_home_win">
            <TomorrowVipHomeWin />
          </Route>
          <Route path="/tomorrow_all_gold_cards">
            <TomorrowAllGold />
          </Route>
          <Route path="/tomorrow_events">
            <TomorrowEvents />
          </Route>
          <Route path="/after_tomorrow">
            <AfterTomorrow />
          </Route>
          <Route path="/after_tomorrow_single">
            <AfterTomorrowSingle />
          </Route>
          <Route path="/after_tomorrow_vip">
            <AfterTomorrowVip />
          </Route>
          <Route path="/after_tomorrow_total_1_5">
            <AfterTomorrowTotal15 />
          </Route>
          <Route path="/after_tomorrow_vip_home_win">
            <AfterTomorrowVipHomeWin />
          </Route>
          <Route path="/after_tomorrow_all_gold_cards">
            <AfterTomorrowAllGold />
          </Route>
          <Route path="/after_tomorrow_events">
            <AfterTomorrowEvents />
          </Route>
          <Route path="/day_after_tomorrow">
            <DayAfterTomorrow />
          </Route>
          <Route path="/day_after_tomorrow_vip">
            <DayAfterTomorrowVip />
          </Route>
          <Route path="/day_after_tomorrow_total_1_5">
            <DayAfterTomorrowTotal15 />
          </Route>
          <Route path="/day_after_tomorrow_all_gold_cards">
            <DayAfterTomorrowAllGold />
          </Route>
          <Route path="/day_after_tomorrow_events">
            <DayAfterTomorrowEvents />
          </Route>
          <Route path="/day_after_tomorrow_single">
            <DayAfterTomorrowSingle />
          </Route>
          <Route path="/live">
            <Live />
          </Route>
          <Route path="/Jackpot">
            <JackPot />
          </Route>
          <Route path="/JackPot_last_1">
            <JackPotLast1 />
          </Route>
          <Route path="/JackPot_last_2">
            <JackPotLast2 />
          </Route>
          <Route path="/JackPot_last_3">
            <JackPotLast3 />
          </Route>
          <Route path="/Statistics_15">
            <Statistics15 />
          </Route>
          <Route path="/Statistics_30">
            <Statistics30 />
          </Route>
          <Route path="/Statistics_60">
            <Statistics60 />
          </Route>
          <Route path="/Statistics_90">
            <Statistics90 />
          </Route>
          <Route path="/Statistics_180">
            <Statistics180 />
          </Route>
          <Route path="/Pay_amount_100">
            <PayAmount100 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
